html,
body,
#reactapp,
.App {
  height: 100%;
}

.App {
  display: flex;
}

* {
  box-sizing: border-box;
}

#root {
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0px;
  padding: 0px
}

.app-container {
  flex: 1;
  height: 100%;
  width: 100%;
  overflow: auto;
  margin: 0px;
  padding: 0px;
  background: linear-gradient(to bottom, #000000, #424242);
}

.routes-container {
  flex: 1;
  height: 100%;
  width: 100%;
  padding: 0px;
}

/* Header / App bar */
.header-container {
  position: absolute;
  top: 0px;
  z-index: 9999999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #000000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  padding-left: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 12%;
  height: 60px;
  width: 60px; 
}

.header-logo-container img {
  height: 50%;
}

.small-logo {
  display: none;
}

.header-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1; /* Allow it to grow */
  margin-left: auto; /* Auto margin on the left */
  margin-right: auto; 
}

.header-text-container span {
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 0.8em;
}

.header-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 60px;
  height: 60px;
}

/* header button */
.css-6z4he2-MuiButtonBase-root-MuiButton-root {
  box-shadow: none !important;
  height: 60px !important;
  width: 60px !important;
}

.header-bottom-title-container {
  display: flex;
  top: 60px;
  z-index: 9999999;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 30px;
  font-size: 0.9em;
  padding-left: 30px;
  background-color: #000000;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
}

.header-bottom-title-container span {
  color: #FFFFFF;
}

/* Splash page */
.splash-container {
  z-index: 99;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  animation: fadeInOut 300ms;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.splash-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Tools */
.to-upper-case {
  text-transform: uppercase;
}

/* unvisited link */
a:link {
  color: #FFFFFF;
}

/* visited link */
a:visited {
  color: #FFFFFF;
}

/* mouse over link */
a:hover {
  color: #FFFFFF;
}

/* selected link */
a:active {
  color: #FFFFFF;
}

/* Carousel */
.carousel-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

/* Swiper - image carousel */
.swiper-button-next,
.swiper-button-prev {
  color: #FFFFFF !important;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 30px;
}

.swiper-pagination-bullet swiper-pagination-bullet-active {
  color: #FFFFFF !important;
  background-color: #FFFFFF !important;
}

.swiper-pagination-bullet {
  background-color: #ECAA1D !important;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #ECAA1D !important;
}

.swiper-horizontal>.swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal,
.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite,
.swiper-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  height: 8px !important;
}

/* Serial number */
.ser-no-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: visible;
}

.ser-no-image-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ser-no-buttons-wrapper {
  padding-top: 10px;
}

.ser-no-image {
  max-width: 80%;
}

/* ItemSelectItem */
.isi-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  /* Responsive behavior */
  width: 100%;
  /* Use full width */
  margin: auto;
  /* Center the box */
  margin-top: 0;
  /* Reset any negative margins */
  padding: 10px;
  /* Add some padding for spacing */
  box-sizing: border-box;
  aspect-ratio: 5 / 7;
  overflow: hidden;
  justify-self: center;
  align-self: center;
}

.isi-image-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.isi-text {
  text-transform: uppercase;
  margin-top: 0.5em;
}

.isi-image {
  width: 100%;
  /* Make image responsive */
  height: auto;
  /* Keep the aspect ratio */
  display: block;
}

.image-container {
  position: relative;
  overflow: hidden;
  /* Hide the sliding images */
  height: auto;
  width: 100%;
}

.isi-image,
.isi-hover-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.5s ease-in-out;
  object-fit: cover;
}

.isi-image {
  transform: translateY(0);
}

.isi-hover-image {
  transform: translateY(100%);
  /* Start below the view */
}

/* Slide out */
.slide-out {
  transform: translateY(-100%);
  /* Slide out to the top */
}

/* Slide in */
.slide-in {
  transform: translateY(0);
  /* Slide in from the bottom */
}

/* product card */
.product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: auto;
  color: #ffffff;
  background-color: #4B4B4B;
  border-radius: 10px;
  overflow: hidden;
  transition: background-color 0.3s;
  width: 100%;
  aspect-ratio: 5 / 7;
}

.product-card.selected {
  border: 2px solid #ECAA1D;
}

.product-card-logo {
  padding: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-card-image {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-card-logo img {
  width: 70%;
}
.product-card-image img {
  width: 100%;
}

.product-card-image img, .product-card-logo img {
  height: auto;
}

.product-card-footer {
  padding: 1em;
  text-align: center;
}

.product-card-footer .product-card-text {
  margin-bottom: 1em;
}

/* carousel */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  width: 80%;
}

.swiper-slide:nth-child(2n) {
  width: 60%;
}

.swiper-slide:nth-child(3n) {
  width: 40%;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

/* Carousel item */
.carousel-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-top: 20px;
  min-height: 200px;
  margin-top: 30px;
}

.carousel-item-text-button-wrapper {
  display: flex;
  flex-direction: column; /* Changed to column for better stacking on small screens */
  align-items: center; /* Center align the items */
  justify-content: center; /* Center justify the items */
  width: 100%;
}

.carousel-item-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Center the button and text */
  width: 100%;
  padding: 15px; /* Reduced padding */
}

.carousel-item-button {
  padding-right: 5px;
}

.carousel-item-button-wrapper span {
  padding: 5px;
  color: #FFFFFF;
  font-size: 1em;
}

.carousel-item-bottom-button {
  padding: 5px;
  width: 100%; /* Full width for the button */
  display: flex;
  justify-content: center; /* Center the button */
}

/* Sign-in & Sign-up */
.sign-x-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 300px);
  overflow-y: auto;
  margin-top: 30px;
}

/* user info */
.user-info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.user-info-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 360px;
}

/* Home */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 100%;
  height: 100%;
  justify-content: center;
}

.home-item-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
  gap: 5%;
  justify-content: center;
  padding: 1rem;
  align-items: center;
  box-sizing: border-box;
  transition: background-color 0.3s;
  cursor: pointer;
}

/* Serials */
.serials-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #e0e0e0;
  -ms-overflow-style: none;
  align-items: center;
  justify-content: center;
}

.serials-item-wrapper {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(1, minmax(300px, auto));
  padding-bottom: 180px;
}

/* Installer status */
.m10-summary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 970px;
  height: 100%;
  margin-top: -60px;
  margin-bottom: -60px;
  margin-left: auto;
  margin-right: auto;
  color: #FFFFFF;
}

.m10-summary-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.m10-summary-top-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;
}

.m10-summary-top-box-item-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 50%;
}

.m10-summary-top-box-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.m10-summary-top-box-item h3 {
  font-size: 1em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.m10-summary-bottom-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
}

.m10-summary-bottom-box-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
}

.m10-summary-rest-items-container {
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 10px;
  margin-left: 15px;
  padding: 20px;
  border: solid 1px rgba(255, 255, 255, 0.5);
  border-radius: 25px;
}

/* User products*/
.user-products-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: calc(100vh - 250px);
  width: 100%;
  overflow-y: auto;
}

/* Lens container*/
.lens-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: calc(100vh - 120px);
  width: 100%;
}

/* product item */
.product-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 1rem;
  padding: 1rem;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  width: 250px;
  /* Adjust width as needed */
  height: 400px;
  /* Adjust height as needed */
  transition: 0.3s;
  position: relative;
  /* For absolute positioning of the button */
}

.product-item-image-wrapper {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.product-item-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.product-item-button-wrapper {
  position: absolute;
  /* Position the button at the bottom */
  bottom: -20px;
  /* Adjust as needed to move the button lower */
  left: 50%;
  transform: translateX(-50%);
}

.product-item-button {
  border-radius: 20px;
  font-weight: bold;
  background-color: black;
  color: white;
  border: 2px solid yellow;
  /* Adjust the color as needed */
}

.product-item-button-selected {
  background-color: #FFD700;
  /* When selected */
}

.product-item-title {
  font-size: 1.5em;
  /* Adjust the font size as needed */
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  /* Space between title and image */
  color: white;
  /* Text color */
}

/* Lens item */
.lens-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 35vh;
  background-color: #4B4B4B;
}

.lens-item-text-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  padding-left: 5%;
  width: 100%;
  color: #FFFFFF;
}

.lens-item-text-wrapper-even {
  justify-content: space-between;
  padding-right: 5%;
}

.lens-item-text-wrapper span {
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: bold;
}

.lens-item-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 50%;
}

.lens-item-image-wrapper img {
  height: 20vh;
  width: 20vh;
  object-fit: cover;
}

.lens-item-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  width: 100%;
  padding-right: 3%;
}

/* inner drop shadow */
.inner-shadow {
  transition: all 0.5s ease-in-out;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  cursor: pointer;
}

.inner-shadow:hover {
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.3);
}



/* logo */
.regular-logo {
  display: block; /* Show by default */
}

.small-logo {
  display: none; /* Hide by default */
}


/************* CSS MEDIA QUERIES ********************/

/* Extra Small Devices (Phones, less than 400px) */
@media only screen and (max-width: 299px) {

  .regular-logo {
    display: none;
  }

  .small-logo {
    display: block;
  }

  .ser-no-image {
    max-width: 100%;
  }

  .header-left-text-container {
    width: 20%;
    padding-left: 20px;
  }

  .m10-summary-box {
    width: 100vw;
    height: 75vh;
  }

  .m10-summary-top-box-item-wrapper {
    width: 100%;
    padding: 20px;
    height: 50%;
  }

  .m10-summary-bottom-box {
    padding: 20px;
  }

  .lens-item-container {
    height: 25vh;
  }

  .carousel-item-container {
    min-height: 200px;
  }

  .home-container {
    justify-content: flex-start;
  }

  .product-card-logo img, .product-card-image img {
    width: 60%;
  }
}

@media only screen and (min-width: 324px) and (max-width: 374px) {

  .regular-logo {
    display: none;
  }

  .small-logo {
    display: block;
  }

  .ser-no-image {
    max-width: 100%;
  }

  .header-left-text-container {
    width: 20%;
    padding-left: 20px;
  }

  .m10-summary-box {
    width: 100vw;
    height: 75vh;
  }

  .m10-summary-top-box-item-wrapper {
    width: 100%;
    padding: 20px;
    height: 50%;
  }

  .m10-summary-bottom-box {
    padding: 20px;
  }

  .lens-item-container {
    height: 25vh;
  }

  .carousel-item-container {
    min-height: 200px;
  }

  .home-container {
    justify-content: flex-start;
  }

  .product-card-logo img, .product-card-image img {
    width: 60%;
  }
}

/* Small devices (Portrait tablets and large phones, 400px and up) */
@media only screen and (min-width: 375px) and (max-width: 767px) {
  .serials-item-wrapper {
    grid-template-columns: repeat(1, minmax(200px, auto));
  }

  .home-container {
    justify-content: flex-start;
    padding-bottom: 150px;
  }

  .m10-summary-box {
    width: 100vw;
    height: 50vh;
  }

  .lens-item-container {
    height: 25vh;
  }

  .lens-item-image-wrapper img {
    height: 15vh;
    width: 15vh;
    object-fit: cover;
  }

  .m10-summary-box {
    width: 100vw;
    height: 75vh;
  }

  .regular-logo {
    display: none;
  }

  .small-logo {
    display: block;
  }

  .carousel-item-container img {
    width: 40vh; /* Full width of the container */
    height: auto; /* Adjusts height automatically */
    padding: 20px;
  }
  .product-card {
    max-width: 100%; /* Allow the card to expand fully on small screens */
    aspect-ratio: 5 / 7; /* Maintain aspect ratio */
  }

  .product-card-logo img, .product-card-image img {
    width: 80%;
  }
}

/* Medium Devices (Landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {

  .m10-summary-box {
    width: 100vw;
    height: 75vh;
  }

  .ser-no-image {
    max-width: 50%;
  }

  .lens-item-container {
    height: 40vh;
  }

  .product-card {
    max-width: 100%; /* Allow the card to expand fully on small screens */
    aspect-ratio: 5 / 7; /* Maintain aspect ratio */
  }

  .home-container {
    justify-content: center;
  }
}

/* Large Devices (Laptops/Desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  

  .m10-summary-box {
    width: 50vw;
    height: 50vh;
  }

  .lens-item-container {
    height: 35vh;
  }

  .home-container {
    justify-content: center;
  }
}

/* Large screens adjustments */
@media only screen and (min-width: 600px) {

  .carousel-item-container img {
    width: auto;
    height: 55vh;
  }
}

/* Extra Large Devices (Large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .m10-summary-box {
    width: 50vw;
    height: 50vh;
  }

  .ser-no-image {
    max-width: 60%;
  }
}

/* Landscape orientation adjustments for all screens */
@media only screen and (max-height: 910px) and (orientation: landscape) {
  .m10-summary-box {
    height: auto;
    overflow-y: auto;
  }

  .carousel-item-container img {
    height: 25vh;
    width: auto;
  }

  .app-container {
    overflow-y: auto; 
  }
  
  .carousel-item-button-wrapper span {
    font-size: 0.7em;
  }
}


/* mobile */
@media (max-width: 768px) {
  .home-container {
    overflow-y: auto;
    position: relative;
  }

  .home-item-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* tablet -> */
@media (min-width: 769px) {
  .home-container {
    overflow: auto;
  }

  .home-container {
    justify-content: flex-start;
  }

  .home-item-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 840px) {
  .home-container {
    overflow-y: auto;
    position: relative;
  }

  .home-container {
    justify-content: flex-start;
  }

  .home-item-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .carousel-item-button-wrapper span {
    font-size: 0.7em;
  }

  .app-container {
    overflow: auto;
  }
}

/* tablet -> */
@media (min-width: 1250px) {
  .home-container {
    overflow-y: auto;
    position: relative;
  }

  .home-container {
    justify-content: center;
  }

  .home-item-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
